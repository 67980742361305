import React from "react"

const Newsletter = ({ siteTitle }) => (
  <div id="mc_embed_signup">
    <form action="https://lieblingsplatz-hotels.us20.list-manage.com/subscribe/post?u=31f0938694bbee0b3a25162a6&amp;id=237f24e804" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
      <div id="mc_embed_signup_scroll">
        <div className="mc-field-group">
          <label htmlFor="mce-EMAIL" className="d-none">Email</label>
          <input placeholder="Email" type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
        </div>
        <div id="mce-responses" className="clear">
          <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
          <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
        </div>
        <div style={{position: 'absolute', left: -5000}} aria-hidden="true">
          <input type="text" name="b_31f0938694bbee0b3a25162a6_237f24e804" tabIndex="-1" />
        </div>
        <div className="clear">
          <input type="submit" value="Jetzt Abonieren" name="subscribe" id="mc-embedded-subscribe" className="button" />
        </div>
      </div>
    </form>
  </div>
)

export default Newsletter
