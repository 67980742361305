import React from "react"
import Newsletter from "./newsletter"
import Icon from "./icon"
import { Link, graphql, useStaticQuery } from "gatsby"
import SocialLinks from "./socialLinks"
import BackgroundImage from "gatsby-background-image"

const Footer = ({ theme }) => {

  const { meinsbur, strandperle, insellodge, strandhotel, lieblingsplatz, braunfels, tirol } = useStaticQuery(
    graphql`
    query {
      meinsbur: file(relativePath: { eq: "hotels/meinsbur-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      strandperle: file(relativePath: { eq: "hotels/strandperle-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      insellodge: file(relativePath: { eq: "hotels/insellodge-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      strandhotel: file(relativePath: { eq: "hotels/strandhotel-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lieblingsplatz: file(relativePath: { eq: "hotels/lieblingsplatz-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      braunfels: file(relativePath: { eq: "hotels/meinsbur-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tirol: file(relativePath: { eq: "hotels/meinsbur-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `
  )

  let background = []
  switch (theme) {
    case 'meinsbur':
      background = [
        meinsbur.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.84) 100%)`
      ]
      break;
    case 'strandhotel':
      background = [
        strandhotel.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(80,100,120,0.67) 0%, rgba(80,100,120,0.84) 100%)`
      ]
      break;
    case 'insellodge':
      background = [
        insellodge.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(63,107,123,0.67) 0%, rgba(63,107,123,0.84) 100%)`
      ]
      break;
    case 'strandperle':
      background = [
        strandperle.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(115,150,180,0.67) 0%, rgba(115,150,180,0.84) 100%)`
      ]
      break;
    case 'braunfels':
      background = [
        braunfels.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(104,97,93,0.67) 0%, rgba(104,97,93,0.84) 100%)`
      ]
      break;
    case 'tirol':
      background = [
        tirol.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(75,109,115,0.67) 0%, rgba(75,109,115.84) 100%)`
      ]
      break;
    default:
      background = [
        lieblingsplatz.childImageSharp.fluid,
        `linear-gradient(180deg, rgba(75,109,115,0.67) 0%, rgba(75,109,115.84) 100%)`
      ]
      break;
  }
  
  return (
    <BackgroundImage
      Tag="footer"
      className={``}
      fluid={background.reverse()}
    > 
      <div className="row no-gutters">
        <div className="col-12">
          <section className="py-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 d-flex mb-5 justify-content-start justify-content-md-between flex-wrap">
                  <div className="pr-3 pb-5">
                    <strong>Lieblingsplatz</strong><br/>
                    <strong>Strandhotel</strong><br/>
                    Im Bad 73<br/>
                    25826 St. Peter-Ording<br/>
                    <a href="tel:048639589325">Telefon 04863 - 95 89 325</a>
                  </div>

                  <div className="pr-3 pb-5">
                    <strong>Strandperle</strong><br/>
                    <strong>Lieblingsplatz Hotel</strong><br/>
                    Kaiserallee 10<br/>
                    23570 Travemünde<br/>
                    <a href="tel:045028889707">Telefon 04502 - 88 89 707</a>
                  </div>

                  <div className="pr-3 pb-5">
                    <strong>Lieblingsplatz</strong><br/>
                    <strong>Bohlendorf</strong><br/>
                    Bohlendorf 6a<br/>
                    18556 Bohlendorf / Rügen<br/>
                    <a href="tel:038391770">Telefon 038391 - 770</a>
                  </div>

                  <div className="pr-3 pb-5">
                    <strong>Meinsbur</strong><br/>
                    <strong>Boutique Hotel</strong><br/>
                    Gartenstraße 2<br/>
                    21227 Bendestorf<br/>
                    <a href="tel:0418377990">Telefon 04183 - 77 990</a>
                  </div>

                  <div className="pr-3 pb-5">
                    <strong>Schlosshotel</strong><br/>
                    <strong>Braunfels</strong><br/>
                    Hubertusstraße 2<br/>
                    35619 Braunfels / Lahn<br/>
                    <a href="tel:064423050">Telefon 06442 - 305 0</a>
                  </div>

                  <div>
                    <strong>Lieblingsplatz</strong><br/>
                    <strong>Tirolerhof</strong><br/>
                    Dorfplatz 8<br/>
                    A-6280 Zell am Ziller<br/>
                    <a href="tel:048639589325">Telefon +43 5282 - 55 007</a>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-md-2 py-3 py-md-0">
                  <h3 className="h6 text-uppercase">ABOUT</h3>
                  <nav className="nav flex-column">
                    <Link to={'/philosophie'} className="nav-link">PHILOSOPHIE</Link>
                    <Link to={'/jobs'} className="nav-link">JOBS</Link>
                    <Link to={'/presse'} className="nav-link">PRESSE</Link>
                    <Link to={'/kontakt'} className="nav-link">KONTAKT</Link>
                  </nav>
                </div>
                <div className="col-12 col-md-2 py-3 py-md-0">
                  <h3 className="h6 text-uppercase">LEGAL</h3>
                  <nav className="nav flex-column">
                    <Link to={'/impressum'} className="nav-link">IMPRESSUM</Link>
                    <Link to={'/datenschutz'} className="nav-link">DATENSCHUTZ</Link>
                    <Link to={'/agb'} className="nav-link">AGB</Link>
                  </nav>
                </div>
                <div className="col-12 col-md-5 py-3 py-md-0 text-center">
                    <p>ist ein</p>
                    <Icon name="lpLogo" width="180" height="50" />
                    <div className="mt-4 justify-content-center d-flex">
                      <SocialLinks />
                    </div>
                  </div>
                <div className="col-12 col-md-3 py-3 py-md-0">
                  <h3 className="h6 text-uppercase">NEWSLETTER</h3>
                  <p>Die besten Angebote, die wichtigsten News rund um LIEBLINGSPLATZ bekommt ihr mit unserem Newsletter. Tragt Euch ein!</p>
                  <Newsletter />
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="col-12">
          <section className="copyright py-1">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <span className="color-hl copyright-text">© Lieblingsplatz Hotels &amp; Restaurants 2013-2020. Alle Rechte vorbehalten.</span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Footer