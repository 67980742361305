import React from "react"
import Icon from "./icon"

const Phone = ({ theme }) => {

  let phone = '04863 - 95 89 325'
  switch (theme) {
    case 'meinsbur':
      phone = '04183 - 77990'
      break;
    case 'strandhotel':
      phone = '04863 - 9589325'
      break;
    case 'insellodge':
      phone = '038391 - 770'
      break;
    case 'strandperle':
      phone = '04502 - 8889707'
      break;
    case 'braunfels':
      phone = '06442 - 3050'
      break;
    case 'tirol':
      phone = '+43 5282 - 55 007'
      break;
    default:
      break;
  }
  
  return (
    <nav className="nav metaNav phone pl-0 d-none d-lg-flex">
      <a href={`tel:${phone}`} className="nav-link">
        <Icon name="phone" width="30" height="30" />
        {phone}
      </a>
    </nav>
  )
}

export default Phone
