import React from "react"
import facebook from '../images/icons/facebook.svg'
import youtube from '../images/icons/youtube.svg'
import twitter from '../images/icons/twitter.svg'
import instagram from '../images/icons/instagram.svg'
import scrollArrowUp from '../images/icons/scroll-arrow-up.svg'
import phone from '../images/icons/phone.svg'
import lpLogo from '../images/logos/lp-logo.svg';
import chevronLeft from '../images/icons/chevron-left.svg'
import chevronRight from '../images/icons/chevron-right.svg'
import angebote from '../images/icons/angebote.svg'
import gutschein from '../images/icons/gutschein.svg'
import besterPreis from '../images/icons/bester-preis.svg'
import pdf from '../images/icons/pdf.svg'
import speisekarte from '../images/icons/speisekarte.svg'
import parken from '../images/icons/parken.svg'
import checkin from '../images/icons/checkin.svg'
import fruehstueck from '../images/icons/fruehstueck.svg'
import hunde from '../images/icons/hunde.svg'
import heart from '../images/icons/heart.svg'
import comment from '../images/icons/comment.svg'
import play from '../images/icons/play.svg'

const Icon = ({ name, width, height }) => {

  const icons = [
    { name: "facebook", icon: facebook },
    { name: "youtube", icon: youtube },
    { name: "twitter", icon: twitter },
    { name: "instagram", icon: instagram },
    { name: "scrollArrowUp", icon: scrollArrowUp },
    { name: "phone", icon: phone },
    { name: "lpLogo", icon: lpLogo },
    { name: "chevronLeft", icon: chevronLeft },
    { name: "chevronRight", icon: chevronRight },
    { name: "angebote", icon: angebote },
    { name: "gutschein", icon: gutschein },
    { name: "besterPreis", icon: besterPreis },
    { name: "pdf", icon: pdf },
    { name: "speisekarte", icon: speisekarte },
    { name: "hunde", icon: hunde },
    { name: "parken", icon: parken },
    { name: "fruehstueck", icon: fruehstueck },
    { name: "checkin", icon: checkin },
    { name: "heart", icon: heart },
    { name: "comment", icon: comment },
    { name: "play", icon: play }
  ]

  const currentIcon = icons.filter(item => item.name === name)[0]

  if (!currentIcon) {
    return null
  }
  
  return (
    <svg width={width} height={height}>
      <use xlinkHref={`#${currentIcon.icon.id}`} />
    </svg>
  )
}

export default Icon