import React from "react"
import Icon from "./icon"

const BookNow = ({ theme }) => {

  let bookingPage = ''
  switch (theme) {
    case 'meinsbur':
      bookingPage = '/hotel-meinsbur-bendestorf/buchen'
      break;
    case 'strandhotel':
      bookingPage = '/strandhotel-st-peter-ording/buchen'
      break;
    case 'insellodge':
      bookingPage = '/hotel-bohlendorf-ruegen/buchen'
      break;
    case 'strandperle':
      bookingPage = '/hotel-strandperle-travemuende/buchen'
      break;
    case 'tirol':
      bookingPage = '/hotel-tirol/buchen'
      break;
    case 'braunfels':
      bookingPage = '/schlosshotel-braunfels/buchen'
      break;
    default:
      bookingPage = '#hotel_overview'
      break;
  }
  
  return (
    <nav className="nav metaNav bookNow pr-0 d-none d-lg-flex">
      <a href={bookingPage} className="nav-link">
        <Icon name="checkin" width="30" height="30" />
        <span className="pl-2">Jetzt Buchen</span>
      </a>
    </nav>
  )
}

export default BookNow
