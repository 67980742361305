import React from "react"
import Icon from "./icon"

const SocialLinks = ({ mobileNav }) => (
  (mobileNav === true) ? (
    <ul className="nav social-nav mt-4 align-items-center justify-content-center d-flex d-md-none">
      <a href="https://www.facebook.com/LieblingsplatzHotels" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="facebook" width="30" height="30" />
      </a>
      <a href="https://www.instagram.com/lieblingsplatz_hotels/" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="instagram" width="30" height="30" />
      </a>
      <a href="https://www.youtube.com/channel/UCR4pNyxbuSXoM7Ohk7yw7jg" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="youtube" width="30" height="30" />
      </a>
      <a href="https://twitter.com/LieblingsplatzH" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="twitter" width="30" height="30" />
      </a>
    </ul>
  ) : (
    <ul className="nav social-nav align-items-center d-none d-md-flex">
      <a href="https://www.facebook.com/LieblingsplatzHotels" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="facebook" width="20" height="20" />
      </a>
      <a href="https://www.instagram.com/lieblingsplatz_hotels/" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="instagram" width="20" height="20" />
      </a>
      <a href="https://www.youtube.com/channel/UCR4pNyxbuSXoM7Ohk7yw7jg" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="youtube" width="20" height="20" />
      </a>
      <a href="https://twitter.com/LieblingsplatzH" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="twitter" width="20" height="20" />
      </a>
    </ul>
  )
)

export default SocialLinks
