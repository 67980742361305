import React from "react"
import { Container } from "react-bootstrap"
import MainNav from "./mainNav"
import Phone from "./phone"
import BookNow from "./bookNow"

const Header = ({ theme }) => (
  <header>
    <Container fluid>
      <div className="d-flex align-items-baseline">
        <Phone theme={theme} />
        <MainNav theme="lieblingsplatz" originalTheme={theme} />
        <BookNow theme={theme} />
      </div>
    </Container>
    <MainNav theme={theme} originalTheme={theme} hotelNavigation={true} />
  </header>
)

export default Header
