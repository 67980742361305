import React, { useEffect, useRef } from "react"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"

const EmbedStaticJS = ({ theme }) => {
  const inputEl = useRef(null)

  useEffect(() => {
    let hotel = null
    switch (theme) {
      case 'meinsbur':
        hotel = 'S003029/b95ca920-7b4a-0135-6031-005056823ce5'
        break;
      case 'strandhotel':
        hotel = 'S000291/0f41cf90-6c91-0132-afd9-0050568825b4'
        break;
      case 'insellodge':
        hotel = 'S003331/5db5be50-c86e-0135-cb03-0050568253f2'
        break;
      case 'strandperle':
        hotel = 'S000290/48bbcb40-6c8f-0132-afd8-0050568825b4'
        break;
      case 'braunfels':
        hotel = 'S003801/8ab30780-02ce-0137-ccc2-0050568253f2'
        break;
      case 'tirol':
        hotel = 'S001247/e3820b10-fee5-0133-5945-005056823ce5'
        break;
      default:
        break;
    }
    
    if (hotel) {
      const script = document.createElement('script')
      script.src = `https://cloud.seekda.com/w/w-dynamic-shop/hotel:${hotel}.js`
      script.async = true
      inputEl.current.appendChild(script)
      // console.log("debug")
    }
  }, [theme]);

  return (
    <div ref={inputEl}></div>
  )
}

const Layout = ({ children, theme, bgImage }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className={theme}>
        <EmbedStaticJS theme={theme} />
        <Header theme={theme} />
        <main>
          {children}
        </main>
        <Footer theme={theme} />
      </div>
    )}
  />
)

export default Layout
