import React, { useState } from 'react'
import SocialLinks from "./socialLinks"
import { Link } from "gatsby"
import Navbar from 'react-bootstrap/Navbar'
import Icon from './icon'

const siteMap = {
  lieblingsplatz: {
    logo: {
      name: "lieblingsplatz",
      alt: "Lieblingsplatz Hotels Logo",
      url: "/"
    },
    left: [
      {label: "Startseite", url: "/"},
      {label: "Philosophie", url: "/philosophie"}
    ],
    right: [
      {label: "Bilder", url: "/bilder"},
      {label: "Kontakt", url: "/kontakt"}
    ]
  },
  meinsbur: {
    logo: {
      name: "meinsbur",
      alt: "Hotel Meinsbur Bendestorf Logo",
      url: "/hotel-meinsbur-bendestorf/"
    },
    left: [
      {label: "Meinsbur Bendestorf", url: "/hotel-meinsbur-bendestorf/"},
      {label: "Tipps", url: "/hotel-meinsbur-bendestorf/tipps"},
      {label: "Zimmer", url: "/hotel-meinsbur-bendestorf/zimmer"},
      {label: "Essen & Trinken", url: "/hotel-meinsbur-bendestorf/restaurant"},
      {label: "Ferienwohnungen", url: "/hotel-meinsbur-bendestorf/ferienwohnungen"},
      {label: "Events", childs: [
        {label: "Eventkalender", url: "/hotel-meinsbur-bendestorf/eventkalender"},
        {label: "Feiern", url: "/hotel-meinsbur-bendestorf/feiern"},
        {label: "Tagen / VillaB", url: "/hotel-meinsbur-bendestorf/tagen"}
      ]},
      {label: "Angebote", url: "/hotel-meinsbur-bendestorf/angebote"},
      {label: "Kontakt", url: "/hotel-meinsbur-bendestorf/kontakt"}
    ],
    right: []
  },
  strandperle: {
    logo: {
      name: "strandperle",
      alt: "Hotel Strandperle Travemünde Logo",
      url: "/hotel-strandperle-travemuende/"
    },
    left: [
      {label: "Strandperle Travemünde", url: "/hotel-strandperle-travemuende/"},
      {label: "Tipps", url: "/hotel-strandperle-travemuende/tipps"},
      {label: "Zimmer", url: "/hotel-strandperle-travemuende/zimmer"},
      {label: "Essen & Trinken", url: "/hotel-strandperle-travemuende/restaurant"},
      {label: "Feiern", url: "/hotel-strandperle-travemuende/feiern"},
      {label: "Angebote", url: "/hotel-strandperle-travemuende/angebote"},
      {label: "Kontakt", url: "/hotel-strandperle-travemuende/kontakt"}
    ],
    right: []
  },
  strandhotel: {
    logo: {
      name: "strandhotel",
      alt: "Strandhotel St. Peter-Ording Logo",
      url: "/strandhotel-st-peter-ording/"
    },
    left: [
      {label: "Strandhotel St. Peter-Ording", url: "/strandhotel-st-peter-ording/"},
      {label: "Tipps", url: "/strandhotel-st-peter-ording/tipps"},
      {label: "Zimmer", url: "/strandhotel-st-peter-ording/zimmer"},
      {label: "Essen & Trinken", url: "/strandhotel-st-peter-ording/restaurant"},
      {label: "Angebote", url: "/strandhotel-st-peter-ording/angebote"},
      {label: "Kontakt", url: "/strandhotel-st-peter-ording/kontakt"}
    ],
    right: []
  },
  insellodge: {
    logo: {
      name: "insellodge",
      alt: "Hotel Insellodge Rügen Logo",
      url: "/hotel-bohlendorf-ruegen/"
    },
    left: [
      {label: "Lieblingsplatz Bohlendorf", url: "/hotel-bohlendorf-ruegen/"},
      {label: "Tipps", url: "/hotel-bohlendorf-ruegen/tipps"},
      {label: "Zimmer", url: "/hotel-bohlendorf-ruegen/zimmer"},
      {label: "Essen & Trinken", url: "/hotel-bohlendorf-ruegen/restaurant"},
      {label: "Ferienwohnungen", url: "/hotel-bohlendorf-ruegen/ferienwohnungen"},
      {label: "Events", url: "/hotel-bohlendorf-ruegen/feiern"},
      {label: "Angebote", url: "/hotel-bohlendorf-ruegen/angebote"},
      {label: "Kontakt", url: "/hotel-bohlendorf-ruegen/kontakt"}
    ],
    right: []
  },
  braunfels: {
    logo: {
      name: "braunfels",
      alt: "Schlosshotel Braunfels Logo",
      url: "/schlosshotel-braunfels/"
    },
    left: [
      {label: "Schlosshotel Braunfels", url: "/schlosshotel-braunfels/"},
      {label: "Tipps", url: "/schlosshotel-braunfels/tipps"},
      {label: "Zimmer", url: "/schlosshotel-braunfels/zimmer"},
      {label: "Essen und Trinken ", url: "/schlosshotel-braunfels/restaurant"},
      {label: "Ferienwohnungen", url: "/schlosshotel-braunfels/ferienwohnungen"},
      {label: "Events", childs: [
        {label: "Feiern", url: "/schlosshotel-braunfels/feiern"},
        {label: "Tagen", url: "/schlosshotel-braunfels/tagen"}
      ]},
      {label: "Angebote", url: "/schlosshotel-braunfels/angebote"},
      {label: "Kontakt", url: "/schlosshotel-braunfels/kontakt"}
    ],
    right: []
  },
  tirol: {
    logo: {
      name: "tirol",
      alt: "Hotel Tirol Logo",
      url: "/hotel-tirol/"
    },
    left: [
      {label: "Lieblingsplatz Zillertal", url: "/hotel-tirol/"},
      {label: "Tipps", url: "/hotel-tirol/tipps"},
      {label: "Zimmer", url: "/hotel-tirol/zimmer"},
      {label: "Essen & Trinken", url: "/hotel-tirol/restaurant"},
      {label: "Wellness", url: "/hotel-tirol/wellness"},
      {label: "Angebote", url: "/hotel-tirol/angebote"},
      {label: "Kontakt", url: "/hotel-tirol/kontakt"}
    ],
    right: []
  }
}

const MainNav = ({ theme, hotelNavigation, originalTheme }) => {
  const [collapsed, setCollapsed] = useState(false);

  if (hotelNavigation && theme === 'lieblingsplatz') {
    return null
  }

  return (
    <Navbar variant={null} expand="lg" className={`mainnav ${collapsed ? 'collapsed' : ''} ${hotelNavigation ? 'hotel' : ''}`} onToggle={(value) => setCollapsed(value)}>
      <Navbar.Toggle className="navbar-toggler-right" data-target="#navbarMobile" aria-controls="navbarMobile">
        <span className="icon-bar top-bar"></span>
        <span className="icon-bar middle-bar"></span>
        <span className="icon-bar bottom-bar"></span>
      </Navbar.Toggle>
      
      <div className={`navbar-collapse d-none d-lg-flex ${hotelNavigation ? 'justify-content-center' : 'justify-content-end'}`}>
        <ul className="nav navbar-nav">
          {siteMap[theme].left.map((item, index) => (
            <li key={index} className={`nav-item ${item.childs ? 'dropdown' : ''}`}>
              {item.url ?
                <Link to={item.url} className={`nav-link navHover`}>{item.label}</Link>
              : 
                <>
                  <a className="nav-link navHover" href="#!" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {item.label}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {item.childs.map((item, index) => (
                      <Link key={index} to={item.url} className="dropdown-item">{item.label}</Link>
                    ))}
                  </div>
                </>
              }
            </li>
          ))}
        </ul>
      </div>
      
      {!hotelNavigation &&
        <Link to={siteMap[theme].logo.url} className={`navbar-brand logo`}>
          <Icon name="lpLogo" width="280" height="50" />
        </Link>
      }
      
      {(siteMap[theme].right.length) ? (
        <div className="navbar-collapse d-none d-lg-flex">
          <ul className="nav navbar-nav">
          {siteMap[theme].right.map((item, index) => (
            <li key={index} className={`nav-item ${item.childs ? 'dropdown' : ''}`}>
              {item.url ?
                <Link to={item.url} className={`nav-link navHover`}>{item.label}</Link>
              : 
                <>
                  <a className="nav-link navHover" href="/#lieblingsplaetze" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {item.label}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {item.childs.map((item, index) => (
                      <Link key={index} to={item.url} className="dropdown-item">{item.label}</Link>
                    ))}
                  </div>
                </>
              }
            </li>
          ))}
          </ul>
        </div>
      ) : (null)}

      <Navbar.Collapse bsPrefix="collapse" id="navbarMobile">
        <ul className="nav navbar-nav">
          {siteMap[(originalTheme !== theme) ? originalTheme : theme].left.map((item, index) => (
            <li key={index} className={`nav-item ${item.childs ? 'dropdown' : ''}`}>
              {item.url ?
                <Link to={item.url} className={`nav-link navHover ${index === (siteMap[(originalTheme !== theme) ? originalTheme : theme].left.length - 1) ? 'pr-0' : ''}`}>{item.label}</Link>
              : 
                <>
                  <a className="nav-link navHover" href="#!" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {item.label}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {item.childs.map((item, index) => (
                      <Link key={index} to={item.url} className="dropdown-item">{item.label}</Link>
                    ))}
                  </div>
                </>
              }
            </li>
          ))}
          {siteMap[(originalTheme !== theme) ? originalTheme : theme].right.map((item, index) => (
            <li key={index} className={`nav-item ${item.childs ? 'dropdown' : ''}`}>
              {item.url ?
                <Link to={item.url} className={`nav-link navHover ${index === (siteMap[(originalTheme !== theme) ? originalTheme : theme].right.length - 1) ? 'pr-0' : ''}`}>{item.label}</Link>
              : 
                <>
                  <a className="nav-link navHover" href="#!" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {item.label}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {item.childs.map((item, index) => (
                      <Link key={index} to={item.url} className="dropdown-item">{item.label}</Link>
                    ))}
                  </div>
                </>
              }
            </li>
          ))}
        </ul>

        <SocialLinks mobileNav={true} />
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNav
